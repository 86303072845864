@font-face {
    font-family: 'CSChatThai';
    src: url('CSChatThai.ttf') format('truetype'), url('CSChatThai.eot?#iefix') format('eot');
}

* {
    min-height: 0;
    min-width: 0;
  }

.ui-text-header-style {
    font-family: "CSChatThai";
    font-size: 25px;
    color: #757575;
    width: 600px;
    min-width: 100px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-navbar-fixed-style {
    max-width: 1200px;
    background-color: #ffffff;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    width: 100%;
}

.ui-th-page-nav-style {
    font-family: "CSChatThai";
    font-size: 22px;
    color: #757575;
    width: 120px;
    text-align: center;
    text-overflow: ellipsis;
    outline: none;
    cursor: pointer;
    cursor: hand;
}

.ui-th-page-nav-style:hover {
    outline: none;
    cursor: pointer;
    cursor: hand;
}

.ui-text-footer-nav-style {
    font-family: "CSChatThai";
    font-size: 18px;
    color: #757575;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
}

.ui-text-header-section-style {
    font-family: "CSChatThai";
    font-size: 22px;
    color: #757575;
}

.ui-text-header-item-style {
    font-family: "CSChatThai";
    font-size: 22px;
    color: #757575;
    text-align: center;
    padding-top: 10px;
}

.ui-text-desc-item-style {
    font-family: "CSChatThai";
    font-size: 20px;
    color: #757575;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    max-height: 40px;
    max-width: 400px;
    overflow: hidden;
}

.ui-position-header-section-style {
    float: left;
    padding-left: 10px;
    width: 100%;
}

.ui-en-page-nav-style {
    font-family: "CSChatThai";
    font-size: 22px;
    color: #757575;
    width: 120px;
    text-align: center;
    text-overflow: ellipsis;
    outline: none;
    cursor: pointer;
    cursor: hand;
}

.ui-en-page-nav-style:hover {
    cursor: hand;
    outline: none;
}

.ui-image-slider-style {
    width: 1200px;
    height: 600px;
    max-height: 600px;
    overflow: hidden;
    background: #ffffff;
}

.ui-divider-5px-style {
    width: 100%;
    height: 5px;
    max-height: 5px;
    background: transparent;
}

.ui-divider-10px-style {
    width: 100%;
    height: 10px;
    max-height: 10px;
    background: transparent;
}


.ui-divider-30px-style {
    width: 100%;
    height: 30px;
    max-height: 30px;
    background: transparent;
}

.ui-image-item-style {
    width: 385px;
    height: 385px;
    max-width: 385px;
    max-height: 385px;
    overflow: hidden;
}

.ui-image-contact-style {
    width: 200px;
    height: 200px;
    overflow: hidden;
}

.ui-youtube-item-style {
    width: 385px;
    height: 420px;
    max-width: 385px;
    max-height: 420px;
    overflow: hidden;
}

.ui-card-350px-style {
    width: 385px;
    height: 420px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    box-shadow: none;
}

.ui-card-370px-style {
    width: 380px;
    height: 500px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    box-shadow: none;
}

.ui-card-auto-style {
    width: 100%;
    height: auto;
    overflow: hidden;
    box-shadow: none;
}

.ui-card-350px-style:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: hand;
}

.ui-card-370px-style:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: hand;
}

.ui-a-link {
    text-decoration: none !important;
    outline: none;
}

.ui-text-desc-html-style {
    font-family: "CSChatThai";
    font-size: 20px;
    color: #757575;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    max-width: 1200px;
    overflow: hidden;
}

.ui-disabled-link-a {
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none !important;
    outline: none;
}

.ui-youtube-play-overlay {
    position: absolute;
    top: 0;
    width: 500px;
    height: 500p;
    z-index: 1000;
}

.ui-googlemap-style {
    min-width: 400px;
    max-width: 1200px;
    min-height: 300px;
    max-height: 600;
    width: 1200px;
    height: 600px;
}

.ui-input-text-style {
    width: 600px;
    font-family: "CSChatThai";
    font-size: 22px;
}

.ui-card-customer-style {
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    min-height: 200px;
    padding: 5px;
    margin-right: 5px;
    border: 1px solid #f5f5f5;
   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
    border-radius: 8px;   
}

.ui-image-customer-style {
    max-width: 150px;
    max-height: 150px;
    min-width: 150px;
    min-height: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    box-shadow: none;
}

.ui-card-image-style {
    max-width: 150px;
    max-height: 150px;
    min-width: 150px;
    min-height: 150px;
    border: 0px solid #f5f5f5;
}

.ui-card-category-style {
    max-width: 250px;
    max-height: 185px;
    min-width: 250px;
    min-height: 185px;
    margin: 0px;
    border: 0px solid #f5f5f5;
    overflow: hidden;
    box-shadow: none;
}

.ui-header-section {
    background: url('header-section.jpg');
    background-repeat: no-repeat;
    background-size: 250px 40px;
    height: 35px;
    margin-left: -5px;
    padding-top: 10px; 
    padding-left: 50px;
    color: #000000;
    min-width: 200px;
    max-width: 200px;
}

.ui-header-section-1 {
    background: url('header-section.jpg');
    background-repeat: no-repeat;
    background-size: 250px 40px;
    height: 35px;
    margin-left: 0px;
    padding-top: 5px; 
    padding-left: 25px;
    color: #000000;
    min-width: 300px;
    max-width: 400px;
}

.ui-tail-section {
    background: url('header-tail.jpg');
    background-repeat: repeat;
    width: 100%;
    height: 35px;
    margin-top: -3px;
    margin-left: -78px;
}

.ui-tail-section-divider {
    background: url('header-tail.jpg');
    background-repeat: repeat;
    width: 100%;
    height: 35px;
    margin-top: -3px;
    margin-left: 0px;
}

.ui-card-image-lightbox {
    padding   : 5px 5px 5px 5px;
    max-width : 240px;
    max-height: 160px;
    width     : 15vw;
    height    : 10vw;
    border: 1px solid #f5f5f5;
}

.ui-card-image-center-lightbox {
    padding: 5px 5px 5px 5px;
    max-width: 400px;
    max-height: 400px;
    height: 28vw;
    width: 28vw;   
    border: 1px solid #f5f5f5;
}

.ui-card-image-lightbox-xs {
    padding: 5px 5px 5px 5px;
    max-width : 320px;
    max-height: 240px;
    width : 42vw;
    height: 28vw;
    border: 1px solid #f5f5f5;
}


.ui-card-image-center-lightbox-xs {
    padding: 5px 5px 5px 5px;
    max-width: 90vw;
    max-height: 90vw;
    min-width: 50vw;
    min-height: 90vw;
    width: 100%;
    height: auto;
    border: 0px solid #f5f5f5;
}


.ui-card-image-youtube-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ui-card-image-event-center {
    padding: 5px 5px 5px 5px;
    max-width: 98%;
    width: 95%;
    height: auto;
    max-height: auto;
    min-width: 300px;
    min-height: auto;
}

.ui-card-tab-style {
    background-color: #ffffff;
}

.ui-card-event-style {
    max-width: 242px;
    max-height:280px;
    min-width: 242px;
    min-height: 280x;
    padding: 0px;
    margin-right: 5px;
    border: 1px solid #f5f5f5;
   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
    border-radius: 8px;   
}


.ui-event-image-style {
    max-width: 240px;
    max-height: 160px;
    min-width: 240px;
    min-height: 160px;
    border: 0px solid #f5f5f5;
}
