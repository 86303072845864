/*--------------------------------------------------------------------------------------------------*/
.App {
  text-align: center;
}
/*--------------------------------------------------------------------------------------------------*/

* {
  min-height: 0;
  min-width: 0;
}
/*--------------------------------------------------------------------------------------------------*/

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
/*--------------------------------------------------------------------------------------------------*/

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
/*--------------------------------------------------------------------------------------------------*/
.App-title {
  font-size: 1.5em;
}
/*--------------------------------------------------------------------------------------------------*/
.App-intro {
  font-size: large;
}
/*--------------------------------------------------------------------------------------------------*/
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
/*--------------------------------------------------------------------------------------------------*/

.content {
  max-width: 1280px;
  align-content: center;
}